import { createTheme } from '@mui/material/styles'
import { theme } from '@libs-components/utils/theme'

import palette from './palette'
import { notoSans, notoSansTC } from './fonts'

const customMuiTheme = createTheme({
  palette: {
    ...theme.palette,
    primary: {
      light: palette['primary-300'],
      main: palette['primary-500'],
      dark: palette['primary-700'],
      contrastText: palette['grayscale-000'],
    },
    secondary: {
      light: palette['peach-300'],
      main: palette['peach-500'],
      dark: palette['peach-700'],
      contrastText: palette['grayscale-000'],
    },
    error: {
      light: palette['peach-300'],
      main: palette['peach-500'],
      dark: palette['peach-700'],
      contrastText: palette['grayscale-000'],
    },
    warning: {
      light: palette['yellow-300'],
      main: palette['yellow-500'],
      dark: palette['yellow-700'],
      contrastText: palette['grayscale-800'],
    },
    info: {
      light: palette['grayscale-700'],
      main: palette['grayscale-800'],
      dark: palette['grayscale-900'],
      contrastText: palette['grayscale-000'],
    },
    success: {
      light: palette['green-300'],
      main: palette['green-500'],
      dark: palette['green-700'],
      contrastText: palette['grayscale-000'],
    },
    cancel: {
      light: palette['grayscale-100'],
      main: palette['grayscale-200'],
      dark: palette['grayscale-300'],
      contrastText: palette['grayscale-900'],
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          fontFamily: [
            notoSansTC.style.fontFamily,
            notoSans.style.fontFamily,
            '-apple-system',
          ].join(','),
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontSize: '0.875rem',
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: { fontSize: '0.875rem' },
      },
    },
  },
})

export default customMuiTheme
