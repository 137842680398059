import { ENV } from '@/constants/env'

export const isProd = () => {
  return process.env.NEXT_PUBLIC_STAGE === ENV.PROD
}

export const isDev = () => {
  return process.env.NEXT_PUBLIC_STAGE === ENV.LOCAL
}

export const stagedKey = (key: string) => {
  if (isProd()) return key
  return `${process.env.NEXT_PUBLIC_STAGE}-${key}`
}

export const inClient = () => {
  return typeof window === 'object'
}

export const STAGE = process.env.NEXT_PUBLIC_STAGE
