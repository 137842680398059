import CloseIcon from '@mui/icons-material/Close'
import { ToastContainer as DefaultToastContainer } from 'react-toastify'
import styled from '@emotion/styled'

import TailwindConfig from 'tailwind.config'

const Colors = TailwindConfig.theme.extend.colors

const WuToastContainer = () => {
  return (
    <StyledToastContainer
      position='bottom-right'
      pauseOnHover
      closeOnClick
      draggable={false}
      autoClose={5000}
      pauseOnFocusLoss
      closeButton={CloseButton}
    />
  )
}

export default WuToastContainer

const StyledToastContainer = styled(DefaultToastContainer)`
  .Toastify__progress-bar--info {
    background-color: ${Colors['grayscale-300']};
  }

  .Toastify__progress-bar--success {
    background-color: ${Colors['green-500']};
  }

  .Toastify__progress-bar--warning {
    background-color: ${Colors['yellow-500']};
  }

  .Toastify__progress-bar--error {
    background-color: ${Colors['peach-500']};
  }

  .Toastify__toast-body {
    font-family: ${TailwindConfig.theme.extend.fontFamily.noto.join(',')};
    color: ${Colors['grayscale-800']};
    font-weight: bold;
    font-size: 0.875rem;
    padding-right: 0.5rem;
  }

  .Toastify__toast {
    padding: 0.75rem 0.625rem;
    min-height: 2.875rem;
  }
`

const CloseButton = () => <CloseIcon className='text-grayscale-800 w-5 h-5 my-auto' />
