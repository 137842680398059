globalThis["__sentryRewritesTunnelPath__"] = undefined;
globalThis["SENTRY_RELEASE"] = {"id":"fA-zbbXpEESG6-wUFWDWU"};
globalThis["__sentryBasePath"] = undefined;
globalThis["__rewriteFramesAssetPrefixPath__"] = "";
// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/
import { isAxiosError } from 'axios'
import * as Sentry from '@sentry/nextjs'
import SentryConfig from './config/sentry/default'

Sentry.init({
  ...SentryConfig,
  ignoreErrors: [
    /Network\s+Error/i,
    /timeout\s+exceeded/,
    /Loading\s+CSS\s+chunk\s+\d+\s+failed/,
    /ResizeObserver\s+loop\s+limit\s+exceeded/,
    /Non-Error\s+exception\s+captured/,
    /Non-Error\s+promise\s+rejection\s+captured/,
    /Request\s+aborted/,
    /ChunkLoadError/,
  ],
  beforeSend(event, hint) {
    try {
      const originalException = hint.originalException
      if (isAxiosError(originalException)) {
        if (/^timeout.*exceeded$/.test(originalException.message)) return null
        if (originalException.response.status >= 400) return null
      }
      return event
    } catch {
      return event
    }
  },
  integrations: [
    Sentry.browserProfilingIntegration(),
    Sentry.browserTracingIntegration({ enableInp: true }),
  ],
})
